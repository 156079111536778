@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background: #FFFFFF;
}

.text.center {
  text-align: center !important;
}

.field-error {
  color: #881d1d;
}

.input.search input {
  border-radius: 25px !important;
}

.error.field small {
  color: #b05d5b;
}

.HomePage {
  height: 100% !important;
  background-color: #f7fafd !important;
}

.HomePage .ui.menu {
  font-size: 1rem !important;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1) !important;
}

@media only screen and (max-width: 767px) {
  .HomePage .ui.menu .right.desk {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .HomePage .ui.menu .right.mob {
    display: inline-block !important;
    align-self: center;
  }
}

.HomePage .ui.menu .item {
  font-weight: 700 !important;
}

.HomePage .ui.menu .brand img {
  height: 40px !important;
  width: 75px !important;
}

.HomePage .hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/images/coach-bus-rental-800x600.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  color: white !important;
  padding-top: 64px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.HomePage .hero .button {
  padding: 1.5rem 2.375rem 1.5rem 2.375rem;
  border-radius: 2rem;
}

.HomePage .hero .header {
  font-size: 2.5rem;
}

.HomePage .hero p {
  font-size: 1.5rem;
}

.HomePage .description-app {
  padding-top: 8rem;
  background-color: #f7fafd !important;
}

.HomePage .description-app .container {
  padding-bottom: 8rem;
}

.HomePage .description-app .container .grid .column:nth-child(1) .header {
  font-size: 2.6rem;
}

.HomePage .description-app .container .grid .column:nth-child(1) p,
.HomePage .description-app .container .grid .column:nth-child(1) .list {
  font-size: 1.3rem;
}

.HomePage .description-app .container .grid .column:nth-child(2) .header {
  font-size: 2.6rem;
}

.HomePage .description-app .container .grid .column:nth-child(2) p,
.HomePage .description-app .container .grid .column:nth-child(2) .list {
  font-size: 1.3rem;
}

.HomePage .des-2 {
  padding-top: 8rem;
}

.HomePage .testimonials {
  padding-top: 8rem;
}

.HomePage .statistics {
  background-color: #e8eff6;
  padding-top: 4rem;
}

@media only screen and (max-width: 767px) {
  .HomePage .statistics .statistic {
    margin-top: 10px;
    width: 100%;
  }
}

.HomePage .statistics .statistic .icon {
  align-self: center;
  margin-bottom: 20px;
}

.HomePage .tab-menu .ui.menu {
  box-shadow: none !important;
}

.HomePage .tab-menu .ui.menu .item.active {
  background-color: teal;
  color: #FFFFFF;
}

.HomePage .bottom-image-down {
  padding-top: 8rem;
}

.HomePage .bottom-image-down p {
  text-align: center;
  font-size: 1.8rem;
}

.HomePage .bottom-image-down .ui.container {
  text-align: center;
}

.HomePage .bottom-image-down .ui.container .button {
  padding: 1.5rem 2.375rem 1.5rem 2.375rem;
  border-radius: 2rem;
}

.mob-menu {
  width: 100%;
  position: absolute;
  top: 64px;
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .mob-menu {
    display: block !important;
    width: 100%;
  }
}

.mob-menu .ui.menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
