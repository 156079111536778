.LoginPage {
  min-height: 100vh;
  background-color: #FFFFFF !important;
}

.LoginPage .ui.grid {
  margin: 0 !important;
  height: 100vh !important;
  padding: 0 !important;
}

.LoginPage .ui.grid .left-box {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/images/coach-bus-rental-800x600.jpg");
  height: 100vh;
  width: 50% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .LoginPage .ui.grid .left-box {
    display: none !important;
  }
}

.LoginPage .ui.grid .left-box h1.ui.header {
  color: white;
  text-align: center;
}

.LoginPage .ui.grid .right-box {
  width: 50% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100% !important;
  overflow: auto !important;
}

@media only screen and (max-width: 767px) {
  .LoginPage .ui.grid .right-box {
    width: 100% !important;
  }
}

.LoginPage .ui.grid .right-box .row {
  width: 60% !important;
}

@media only screen and (max-width: 767px) {
  .LoginPage .ui.grid .right-box .row {
    width: 100% !important;
  }
}

.LoginPage .ui.grid .right-box .row .logo {
  height: 75px;
  width: 150px;
  resize-mode: contain;
  align-self: center;
}
